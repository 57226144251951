<template>
	<router-view></router-view>
</template>

<script>
	export default {
		name: "AdminView"
	};
</script>

<style></style>
